import {inject, Injectable} from "@angular/core";
import {MessageService} from "primeng/api";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private messageService = inject(MessageService);

  pushErrorToast(error: any) {
    let message = '';
    if (error instanceof HttpErrorResponse) {
      if (error.error['DefaultMessage']) {
        message = error.error['DefaultMessage'];
      } else if (error.error['message']) {
        message = error.error['message'];
      } else {
        message = "Unknown error"
      }
    }
    if (message) {
      this.messageService.add({
        severity: "error",
        summary: "Error Occurred",
        detail: message
      });
    }
  }

  pushSuccessToast(message?: string) {
    if (message) {
      this.messageService.add({
        severity: "success",
        summary: message,
      });
    }

  }
}
