/**
 * SmartCosting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Layer { 
    type: Layer.TypeEnum;
    sublayer: number;
    z: number;
    delta: number | null;
    apress: number;
    fpress: number;
    len_crit: number;
}
export namespace Layer {
    export type TypeEnum = 'upper' | 'lower';
    export const TypeEnum = {
        Upper: 'upper' as TypeEnum,
        Lower: 'lower' as TypeEnum
    };
}


