/**
 * SmartCosting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { HTTPValidationError } from '../model/hTTPValidationError';
// @ts-ignore
import { Project } from '../model/project';
// @ts-ignore
import { ProjectCreate } from '../model/projectCreate';
// @ts-ignore
import { ResponseModel } from '../model/responseModel';
// @ts-ignore
import { ResponseModelProject } from '../model/responseModelProject';
// @ts-ignore
import { ResponseModelProjectList } from '../model/responseModelProjectList';
// @ts-ignore
import { ResponseModelProjectOptionList } from '../model/responseModelProjectOptionList';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateProjectProjectProjectsPostRequestParams {
    projectCreate: ProjectCreate;
}

export interface DuplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePostRequestParams {
    /** The UUID of the project option to duplicate */
    projectOptionUuid: string;
}

export interface GetProjectOptionsProjectProjectsProjectUuidProjectOptionsGetRequestParams {
    /** The UUID of the project */
    projectUuid: string;
    /** Filter by project option status */
    projectOptionStatus?: 'open' | 'won' | 'archived' | null;
}

export interface GetProjectProjectProjectsProjectUuidGetRequestParams {
    /** The UUID of the project */
    projectUuid: string;
}

export interface GetProjectsProjectProjectsGetRequestParams {
    /** Filter by user */
    user?: string | null;
    /** Substring search of project name */
    projectName?: string | null;
    /** Filter by project status */
    projectStatus?: 'open' | 'won' | 'lost' | null;
}

export interface UpdateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatchRequestParams {
    projectOptionUuid: string;
    /** The new status for the project option */
    newStatus: string;
}

export interface UpdateProjectProjectProjectsProjectUuidPutRequestParams {
    projectUuid: string;
    project: Project;
}


@Injectable({
  providedIn: 'root'
})
export class ProjectOverviewService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create Project
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectProjectProjectsPost(requestParameters?: CreateProjectProjectProjectsPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProject>;
    public createProjectProjectProjectsPost(requestParameters?: CreateProjectProjectProjectsPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProject>>;
    public createProjectProjectProjectsPost(requestParameters?: CreateProjectProjectProjectsPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProject>>;
    public createProjectProjectProjectsPost(requestParameters?: CreateProjectProjectProjectsPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectCreate = requestParameters?.projectCreate;
        if (projectCreate === null || projectCreate === undefined) {
            throw new Error('Required parameter projectCreate was null or undefined when calling createProjectProjectProjectsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/project/projects/`;
        return this.httpClient.request<ResponseModelProject>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: projectCreate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Duplicate Project Option
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePost(requestParameters?: DuplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModel>;
    public duplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePost(requestParameters?: DuplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModel>>;
    public duplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePost(requestParameters?: DuplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModel>>;
    public duplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePost(requestParameters?: DuplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling duplicateProjectOptionProjectProjectOptionsProjectOptionUuidDuplicatePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/project/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/duplicate`;
        return this.httpClient.request<ResponseModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Project Options
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectOptionsProjectProjectsProjectUuidProjectOptionsGet(requestParameters?: GetProjectOptionsProjectProjectsProjectUuidProjectOptionsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProjectOptionList>;
    public getProjectOptionsProjectProjectsProjectUuidProjectOptionsGet(requestParameters?: GetProjectOptionsProjectProjectsProjectUuidProjectOptionsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProjectOptionList>>;
    public getProjectOptionsProjectProjectsProjectUuidProjectOptionsGet(requestParameters?: GetProjectOptionsProjectProjectsProjectUuidProjectOptionsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProjectOptionList>>;
    public getProjectOptionsProjectProjectsProjectUuidProjectOptionsGet(requestParameters?: GetProjectOptionsProjectProjectsProjectUuidProjectOptionsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectUuid = requestParameters?.projectUuid;
        if (projectUuid === null || projectUuid === undefined) {
            throw new Error('Required parameter projectUuid was null or undefined when calling getProjectOptionsProjectProjectsProjectUuidProjectOptionsGet.');
        }
        const projectOptionStatus = requestParameters?.projectOptionStatus;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (projectOptionStatus !== undefined && projectOptionStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectOptionStatus, 'project_option_status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/project/projects/${this.configuration.encodeParam({name: "projectUuid", value: projectUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/project_options/`;
        return this.httpClient.request<ResponseModelProjectOptionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Project
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectProjectProjectsProjectUuidGet(requestParameters?: GetProjectProjectProjectsProjectUuidGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProject>;
    public getProjectProjectProjectsProjectUuidGet(requestParameters?: GetProjectProjectProjectsProjectUuidGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProject>>;
    public getProjectProjectProjectsProjectUuidGet(requestParameters?: GetProjectProjectProjectsProjectUuidGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProject>>;
    public getProjectProjectProjectsProjectUuidGet(requestParameters?: GetProjectProjectProjectsProjectUuidGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectUuid = requestParameters?.projectUuid;
        if (projectUuid === null || projectUuid === undefined) {
            throw new Error('Required parameter projectUuid was null or undefined when calling getProjectProjectProjectsProjectUuidGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/project/projects/${this.configuration.encodeParam({name: "projectUuid", value: projectUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ResponseModelProject>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Projects
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectsProjectProjectsGet(requestParameters?: GetProjectsProjectProjectsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProjectList>;
    public getProjectsProjectProjectsGet(requestParameters?: GetProjectsProjectProjectsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProjectList>>;
    public getProjectsProjectProjectsGet(requestParameters?: GetProjectsProjectProjectsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProjectList>>;
    public getProjectsProjectProjectsGet(requestParameters?: GetProjectsProjectProjectsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const user = requestParameters?.user;
        const projectName = requestParameters?.projectName;
        const projectStatus = requestParameters?.projectStatus;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (user !== undefined && user !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>user, 'user');
        }
        if (projectName !== undefined && projectName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectName, 'project_name');
        }
        if (projectStatus !== undefined && projectStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectStatus, 'project_status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/project/projects/`;
        return this.httpClient.request<ResponseModelProjectList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Project Option Status
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch(requestParameters?: UpdateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModel>;
    public updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch(requestParameters?: UpdateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModel>>;
    public updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch(requestParameters?: UpdateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModel>>;
    public updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch(requestParameters?: UpdateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch.');
        }
        const newStatus = requestParameters?.newStatus;
        if (newStatus === null || newStatus === undefined) {
            throw new Error('Required parameter newStatus was null or undefined when calling updateProjectOptionStatusProjectProjectsProjectOptionsProjectOptionUuidStatusPatch.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (newStatus !== undefined && newStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>newStatus, 'new_status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/project/projects/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/status`;
        return this.httpClient.request<ResponseModel>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Project
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectProjectProjectsProjectUuidPut(requestParameters?: UpdateProjectProjectProjectsProjectUuidPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProject>;
    public updateProjectProjectProjectsProjectUuidPut(requestParameters?: UpdateProjectProjectProjectsProjectUuidPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProject>>;
    public updateProjectProjectProjectsProjectUuidPut(requestParameters?: UpdateProjectProjectProjectsProjectUuidPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProject>>;
    public updateProjectProjectProjectsProjectUuidPut(requestParameters?: UpdateProjectProjectProjectsProjectUuidPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectUuid = requestParameters?.projectUuid;
        if (projectUuid === null || projectUuid === undefined) {
            throw new Error('Required parameter projectUuid was null or undefined when calling updateProjectProjectProjectsProjectUuidPut.');
        }
        const project = requestParameters?.project;
        if (project === null || project === undefined) {
            throw new Error('Required parameter project was null or undefined when calling updateProjectProjectProjectsProjectUuidPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/project/projects/${this.configuration.encodeParam({name: "projectUuid", value: projectUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ResponseModelProject>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: project,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
